<template>
  <div>
    <v-col cols="12">
      <div class="card">
        <div class="card-header card-header-icon card-header-rose">
          <div class="card-icon">
            <i class="material-icons">sticky_note_2</i>
          </div>
          <h3 class="card-title">Folio Temporal</h3>
        </div>
        <div class="card-body">
          <v-row v-for="parte in partes_temporales" v-bind:key="parte.id">
            <v-col>
              <TicketBitacoraNew
                v-bind:parte="parte"
                @clicked="seleccionParte"
              />
            </v-col>
            <v-col>
              <v-btn @click="sincronizar">Sincronizar Turno</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <!--BITACORA DE CARGA-->
        <v-expansion-panel v-if="mostrar.carga">
          <v-expansion-panel-header color="#263238" style="color: #fff;">
            <div>
              BITACORA DE CARGA COSECHA<v-icon
              color="orange"
              title="Guadado Temporal"
              v-if="carga.temporal == 1"
              >mdi-clock</v-icon
            >
            <v-icon
              color="green"
              title="Guadado Definitivo"
              v-if="carga.temporal == 0"
              >mdi-check-circle</v-icon
            >
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mx-0 mt-1">
              <v-col cols="2">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Folio Parte"
                  label="Folio Parte"
                  v-model="parte.folio"
                  dense
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">
                Parámetros medioambientales en centro previo a la carga
              </legend>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-temperature-celsius"
                    placeholder="Temperatura (°C)"
                    suffix="° C"
                    v-model="carga.temperatura_centro"
                    dense
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-seal"
                    placeholder="Oxigeno (mg/L)"
                    suffix="mg/L"
                    v-model="carga.oxigeno_centro"
                    dense
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-seal"
                    placeholder="Saturación (%)"
                    v-model="carga.saturacion_centro"
                    suffix="%"
                    dense
                    type="number"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </fieldset>

            <fieldset class="px-2 py-2 elevation-3" v-if="carga.es_nueva">
              <legend class="rounded-sm px-2 py-1">Información General</legend>
              <v-row class="mx-0">
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Guia Despacho"
                    label="Guia Despacho"
                    v-model="carga.guia_despacho"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaGuiaFormatted"
                        label="Fecha guía de despacho"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="carga.fecha_despacho"
                      @input="menu1 = false"
                      locale="es-cl"
                      prev-icon="chevron_left"
                      next-icon="chevron_right"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="carga.especies_id"
                    :items="especies"
                    item-text="nombre"
                    item-value="id"
                    label="Especie"
                    placeholder="Seleccione especie"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-swim"
                    placeholder="Días de ayuno"
                    label="Días de ayuno"
                    v-model="carga.dias_ayuno"
                    type="text"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--PARAMETROS-->
                <v-col cols="4" v-for="p in parameters" :key="`par_${p.id}`">
                  <v-select
                    v-model="p.valor"
                    prepend-icon="mdi-form-select"
                    :items="p.opciones"
                    :label="p.nombre"
                    item-text="nombre"
                    item-value="id"
                    dense
                  ></v-select>
                </v-col>
                <!--PARAMETROS-->

                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-seal"
                    :placeholder="nbodega1"
                    :label="nbodega1"
                    v-model="carga.sello_babor"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-seal"
                    :placeholder="nbodega2"
                    :label="nbodega2"
                    v-model="carga.sello_central"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" v-if="parte.wellboats_id != 7">
                  <v-text-field
                    prepend-icon="mdi-seal"
                    placeholder="N° sello estribor"
                    label="N° sello estribor"
                    v-model="carga.sello_estribor"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-seal"
                    placeholder="N° sello Yoma 1"
                    label="N° sello Yoma 1"
                    v-model="carga.sello_yoma1"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-seal"
                    placeholder="N° sello Yoma 2"
                    label="N° sello Yoma 2"
                    v-model="carga.sello_yoma2"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </fieldset>
            <fieldset class="px-2 py-2 elevation-3" v-if="!carga.es_nueva">
              <legend class="rounded-sm px-2 py-1">Información General</legend>
              <v-row class="mx-0">
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Guia Despacho"
                    label="Guia Despacho"
                    v-model="carga.guia_despacho"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaGuiaFormatted"
                        label="Fecha guía de despacho"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="carga.fecha_despacho"
                      @input="menu1 = false"
                      locale="es-cl"
                      prev-icon="chevron_left"
                      next-icon="chevron_right"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="carga.especies_id"
                    :items="especies"
                    item-text="nombre"
                    item-value="id"
                    label="Especie"
                    placeholder="Seleccione especie"
                    return-object
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-swim"
                    placeholder="Días de ayuno"
                    label="Días de ayuno"
                    v-model="carga.dias_ayuno"
                    type="text"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-swim"
                    placeholder="Natacion en bodegas"
                    label="Natacion en bodegas"
                    v-model="carga.natacion"
                    counter="20"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-air-conditioner"
                    placeholder="Boqueo en bodegas"
                    label="Boqueo en bodegas"
                    v-model="carga.boqueo"
                    counter="20"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-fish"
                    placeholder="Descamacion"
                    label="Descamacion"
                    v-model="carga.descamacion"
                    counter="20"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-rounded-corner"
                    placeholder="Peces esquinados"
                    label="Peces esquinados"
                    v-model="carga.esquinados"
                    counter="20"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Ataques de lobos"
                    label="Ataques de lobos"
                    v-model="carga.lobos"
                    counter="20"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-medical-bag"
                    placeholder="Peces con heridas"
                    label="Peces con heridas"
                    v-model="carga.heridas"
                    counter="20"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-gate"
                    placeholder="Mortalidad en cerco"
                    label="Mortalidad en cerco"
                    v-model="carga.mortalidad_cerco"
                    counter="20"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-seal"
                    :placeholder="nbodega1"
                    :label="nbodega1"
                    v-model="carga.sello_babor"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-seal"
                    :placeholder="nbodega2"
                    :label="nbodega2"
                    v-model="carga.sello_central"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" v-if="parte.wellboats_id != 7">
                  <v-text-field
                    prepend-icon="mdi-seal"
                    placeholder="N° sello estribor"
                    label="N° sello estribor"
                    v-model="carga.sello_estribor"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-seal"
                    placeholder="N° sello Yoma 1"
                    label="N° sello Yoma 1"
                    v-model="carga.sello_yoma1"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-seal"
                    placeholder="N° sello Yoma 2"
                    label="N° sello Yoma 2"
                    v-model="carga.sello_yoma2"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </fieldset>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">Lances</legend>
              <v-btn @click="agregarLance()" color="green"
                ><v-icon>exposure_plus_1</v-icon></v-btn
              >
              <v-simple-table dense class="mx-2 my-2 elevation-3 text-center">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-center blue-grey darken-4 white-text"
                        rowspan="2"
                      >
                        Número
                      </th>
                      <th
                        class="text-center blue-grey darken-4 white-text"
                        rowspan="2"
                      >
                        Hora Inicio
                      </th>
                      <th
                        class="text-center blue-grey darken-4 white-text"
                        colspan="4"
                      >
                        Parámetros Ambientales
                      </th>
                      <th
                        class="text-center blue-grey darken-4 white-text"
                        rowspan="2"
                      >
                        Número de Peces en lance
                      </th>
                      <th
                        class="text-center blue-grey darken-4 white-text"
                        rowspan="2"
                      >
                        Hora Término
                      </th>
                      <th
                        class="text-center blue-grey darken-4 white-text"
                        rowspan="2"
                      >
                        Tiempo Carga Efectivo
                      </th>
                      <th
                        class="text-center blue-grey darken-4 white-text"
                        rowspan="2"
                      >
                        Acciones
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center blue-grey darken-4 white-text">
                        Hora medición
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Oxigeno (mg/L)
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Saturacion (%)
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        T (°C)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(lance, idx1) in carga.lances"
                      :key="lance.numero"
                    >
                      <td style="border-right: 1px solid black;">
                        {{ lance.numero }}
                      </td>
                      <td style="border-right: 1px solid black;">
                        <v-datetime-picker
                          label="Inicio (Fecha Hora)"
                          :key="lance.key"
                          v-model="lance.hora_inicio"
                          dateFormat="dd-MM-yyyy"
                          :datePickerProps="{
                            locale: 'es-es',
                            prevIcon: 'chevron_left',
                            nextIcon: 'chevron_right',
                          }"
                          :timePickerProps="{ format: '24hr' }"
                          @input="calcularCargaEfectiva(lance)"
                        >
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <td>
                        <v-datetime-picker
                          label="Medicion (Fecha Hora)"
                          :key="lance.key"
                          v-model="lance.hora_medicion"
                          dateFormat="dd-MM-yyyy"
                          :datePickerProps="{
                            locale: 'es-es',
                            prevIcon: 'chevron_left',
                            nextIcon: 'chevron_right',
                          }"
                          :timePickerProps="{ format: '24hr' }"
                        >
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <td>
                        <v-text-field
                          v-model="lance.oxigeno"
                          suffix="mg/L"
                          type="number"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="lance.saturacion"
                          suffix="%"
                          type="number"
                        ></v-text-field>
                      </td>
                      <td style="border-right: 1px solid black;">
                        <v-text-field
                          v-model="lance.temperatura"
                          suffix="° C"
                          type="number"
                        ></v-text-field>
                      </td>
                      <td style="border-right: 1px solid black;">
                        <v-text-field
                          v-model="lance.peces"
                          type="number"
                        ></v-text-field>
                      </td>
                      <td style="border-right: 1px solid black;">
                        <v-datetime-picker
                          label="Termino (Fecha Hora)"
                          :key="lance.key"
                          v-model="lance.hora_termino"
                          dateFormat="dd-MM-yyyy"
                          :datePickerProps="{
                            locale: 'es-es',
                            prevIcon: 'chevron_left',
                            nextIcon: 'chevron_right',
                          }"
                          :timePickerProps="{ format: '24hr' }"
                          @input="calcularCargaEfectiva(lance)"
                        >
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <td style="border-right: 1px solid black;">
                        <v-text-field
                          v-model="lance.carga_efectiva"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td>
                        <a @click="eliminarLance(idx1)"
                          ><span class="material-icons">delete</span></a
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </fieldset>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">Bodegas</legend>
              <v-btn @click="agregarBodega" color="green"
                ><v-icon>exposure_plus_1</v-icon></v-btn
              >
              <v-simple-table dense class="mx-2 my-2 elevation-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center blue-grey darken-4 white-text">
                        N°
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Bodega
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Número de Peces
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Peso Promedio (Kg)
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Biomasa Estimada (Kg)
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Densidad de Carga (Kg/m3)
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        N° Lance
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Jaula
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(detalle, idx) in carga.bodegas"
                      :key="detalle.numero"
                    >
                      <td class="text-center">{{ detalle.numero }}</td>
                      <td class="text-center">
                        <v-select
                          v-model="detalle.bodegas_id"
                          :items="bodegas"
                          item-text="nombre"
                          item-value="id"
                          label="Bodega"
                          placeholder="Seleccione bodega"
                        ></v-select>
                      </td>
                      <!--<td
                        class="text-center"
                        v-if="detalle.bodega.bodega != undefined"
                      >
                        {{ detalle.bodega.bodega.nombre }}
                      </td>
                      <td
                        class="text-center"
                        v-if="detalle.bodega.bodega == undefined"
                      >
                        {{ detalle.bodega.nombre }}
                      </td>-->
                      <td class="text-center">
                        <v-text-field
                          class="input-right"
                          v-model="detalle.peces"
                          type="number"
                          @input="calcularBiomasa(detalle)"
                        ></v-text-field>
                      </td>
                      <td class="text-center">
                        <v-text-field
                          class="input-right"
                          v-model="detalle.peso"
                          type="number"
                          @input="calcularBiomasa(detalle)"
                        ></v-text-field>
                      </td>
                      <td class="text-center">
                        <v-text-field
                          class="input-right"
                          v-model="detalle.biomasa"
                          type="number"
                          readonly
                        ></v-text-field>
                      </td>
                      <td class="text-center">
                        <v-text-field
                          class="input-right"
                          v-model="detalle.densidad"
                          type="number"
                          readonly
                        ></v-text-field>
                      </td>
                      <td class="text-center">
                        <v-text-field
                          class="input-center"
                          v-model="detalle.lances"
                        ></v-text-field>
                      </td>
                      <td class="text-center">
                        <v-text-field
                          class="input-center"
                          v-model="detalle.jaula"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center">
                        <a @click="eliminarBodega(idx)"
                          ><span class="material-icons">delete</span></a
                        >
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2">Total/Prom</td>
                      <td>
                        <v-text-field
                          class="input-right"
                          v-model="totalPeces"
                          type="number"
                          readonly
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          class="input-right"
                          v-model="totalPeso"
                          type="number"
                          readonly
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          class="input-right"
                          v-model="totalBiomasa"
                          type="number"
                          readonly
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          class="input-right"
                          v-model="totalDensidad"
                          type="number"
                          readonly
                        ></v-text-field>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </fieldset>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  solo
                  counter="1000"
                  label="Observaciones"
                  v-model="carga.observaciones"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <v-text-field
                  label="Nombre Jefe de Centro"
                  placeholder="Nombre Jefe de Centro"
                  v-model="carga.jefe_centro"
                  dense
                >
                </v-text-field>

                <!-- <div ref="sketch" id="sketch" style="border :2px solid #3c3c3c;width:100%;height:150px;">
                  <canvas id="canvas"></canvas>
                </div> -->
                <!-- <firma></firma> -->
              </v-col>
              <v-col cols="2"> </v-col>
              <v-col cols="5">
                <v-select
                  v-if="tecnicos.length > 0"
                  v-model="carga.tecnico_wellboat"
                  :items="tecnicos"
                  item-text="nombre"
                  item-value="id"
                  label="Técnico Wellboat"
                >
                </v-select>
                <v-text-field
                  v-if="tecnicos.length == 0"
                  label="Técnico Wellboat"
                  placeholder="Técnico Wellboat"
                  v-model="carga.tecnico_wellboat"
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- alert bitacora de carga -->
            <v-row v-if="alert_carga.mostrar">
              <v-col>
                <v-alert border="left" outlined text :type="alert_carga.tipo">
                  <v-row>
                    <v-col>
                      <h4>{{ alert_carga.mensaje }}</h4>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn
                        :color="alert_carga.color_boton"
                        elevation="2"
                        small
                        dark
                        @click="cerrar_alert('carga')"
                        >Cerrar</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="alert_carga.errores.length > 0">
                    <v-col>
                      <ul>
                        <li v-for="e in alert_carga.errores" :key="e">
                          {{ e }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
            <!-- alert bitacora de carga-->
            <v-row>
              <v-col cols="4" class="text-left">
                <v-btn
                  color=""
                  @click="guardarCarga(1)"
                  :disabled="carga.temporal == 0"
                  >GUARDAR TEMPORAL</v-btn
                >
              </v-col>
              <v-col cols="4" class="pt-0">
                <v-datetime-picker
                  label="Termino (Fecha Hora) CAM"
                  v-model="termino_cam"
                  dateFormat="dd-MM-yyyy"
                  :datePickerProps="{
                    locale: 'es-es',
                    prevIcon: 'chevron_left',
                    nextIcon: 'chevron_right',
                  }"
                  :timePickerProps="{ format: '24hr' }"
                  :disabled="false"
                >
                  <template slot="dateIcon">
                    <v-icon>calendar_today</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>access_time</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-btn color="green" @click="guardarCarga(0)"
                  >GUARDAR CARGA</v-btn
                >
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--BITACORA DE CARGA-->

        <!--BITACORA DE TRASLADO-->
        <v-expansion-panel v-if="mostrar.traslado">
          <v-expansion-panel-header color="#263238" style="color: #fff;">
            <div>
                BITACORA DE TRASLADO
                <v-icon color="green" title="Guadado Definitivo" v-if="traslado.temporal == 0">mdi-check-circle</v-icon>
                <v-icon color="orange" title="Guadado Temporal" v-if="traslado.temporal == 1">mdi-clock</v-icon>
              </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mx-0 mt-1">
              <v-col cols="2">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Folio Parte"
                  label="Folio Parte"
                  v-model="traslado.folio"
                  dense
                  :readonly="true"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="Cliente"
                  label="Cliente"
                  v-model="traslado.empresa"
                  dense
                  :readonly="true"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="Centro Carga"
                  label="Centro Carga"
                  v-model="traslado.centro_carga"
                  dense
                  :readonly="true"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="Centro Descarga"
                  label="Centro Descarga"
                  v-model="traslado.centro_descarga"
                  dense
                  :readonly="true"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="Hora Inicio"
                  label="Hora Inicio"
                  :value="traslado.hora_inicio | format_date_spanish"
                  dense
                  :readonly="true"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  placeholder="Hora Termino"
                  label="Hora Termino"
                  :value="traslado.hora_termino | format_date_spanish"
                  dense
                  :readonly="true"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="mx-0 mt-1">
              <v-col cols="4">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Guia Despacho 1era Carga"
                  label="Guia Despacho 1"
                  v-model="traslado.guia_despacho"
                  @change="checkGuia"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" v-if="traslado.carga_doble">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Guia Despacho 2da Carga"
                  label="Guia Despacho 2"
                  v-model="traslado.guia_despacho_2"
                  @change="checkGuia"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">
                Mediciones de parámetros
              </legend>
              <v-btn @click="addMedicionTraslado()" color="green"
                ><v-icon>exposure_plus_1</v-icon></v-btn
              >
              <v-simple-table dense class="mx-0 elevation-3 text-center">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        rowspan="2"
                        class="text-center blue-grey darken-4 white-text"
                      >
                        Número
                      </th>
                      <th
                        rowspan="2"
                        class="text-center blue-grey darken-4 white-text"
                      >
                        HORA
                      </th>
                      <th
                        colspan="4"
                        class="text-center blue-grey darken-4 white-text"
                        v-for="bodega in bodegas"
                        :key="bodega.id"
                      >
                        {{ bodega.nombre }}
                      </th>
                      <th
                        rowspan="2"
                        class="text-center blue-grey darken-4 white-text"
                      >
                        Acciones
                      </th>
                    </tr>
                    <tr>
                      <template v-for="i in bodegas.length">
                        <th
                          class="text-center blue-grey darken-4 white-text"
                          :key="`${i}o2`"
                        >
                          O2 (mg/L)
                        </th>
                        <th
                          class="text-center blue-grey darken-4 white-text"
                          :key="`${i}ph`"
                        >
                          PH
                        </th>
                        <th
                          class="text-center blue-grey darken-4 white-text"
                          :key="`${i}uv`"
                        >
                          UV (ml/cm2)
                        </th>
                        <th
                          class="text-center blue-grey darken-4 white-text"
                          :key="`${i}t`"
                        >
                          T (°C)
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(parametro,idx1) in traslado.parametros"
                      :key="parametro.numero"
                    >
                      <td>{{ parametro.numero }}</td>
                      <td style="border-right: 1px solid black; width: 180px">
                        <v-datetime-picker
                          label="Hora Medición"
                          v-model="parametro.hora"
                          dateFormat="dd-MM-yyyy"
                          :datePickerProps="{
                            locale: 'es-es',
                            prevIcon: 'chevron_left',
                            nextIcon: 'chevron_right',
                          }"
                          :timePickerProps="{ format: '24hr' }"
                        >
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <template v-for="bodega in parametro.bodegas">
                        <td :key="`${bodega.id}-o2`">
                          <v-text-field
                            v-model="bodega.oxigeno"
                            type="number"
                            step="0.1"
                            class="input-right"
                          ></v-text-field>
                        </td>
                        <td :key="`${bodega.id}-ph`">
                          <v-text-field
                            v-model="bodega.ph"
                            type="number"
                            class="input-right"
                          ></v-text-field>
                        </td>
                        <td :key="`${bodega.id}-uv`">
                          <v-text-field
                            v-model="bodega.uv"
                            type="number"
                            class="input-right"
                          ></v-text-field>
                        </td>
                        <td
                          style="border-right: 1px solid black"
                          :key="`${bodega.id}-t`"
                        >
                          <v-text-field
                            v-model="bodega.temperatura"
                            type="number"
                            class="input-right"
                          ></v-text-field>
                        </td>
                      </template>
                      <td>
                        <a @click="deleteParametroTraslado(idx1)"
                          ><span class="material-icons">delete</span></a
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </fieldset>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  solo
                  counter="500"
                  label="Observaciones"
                  v-model="traslado.observaciones"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-if="tecnicos.length > 0"
                  v-model="traslado.tecnico_wellboat"
                  :items="tecnicos"
                  item-text="nombre"
                  item-value="id"
                  label="Técnico Wellboat"
                  dense
                >
                </v-select>
                <v-text-field
                  v-if="tecnicos.length == 0"
                  label="Técnico Wellboat"
                  placeholder="Técnico Wellboat"
                  v-model="traslado.tecnico_wellboat"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Supervisor"
                  placeholder="Supervisor"
                  v-model="traslado.supervisor"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-if="capitanes.length > 0"
                  v-model="traslado.capitan"
                  :items="capitanes"
                  item-text="nombre"
                  item-value="id"
                  label="Nombre Capitán"
                  dense
                >
                </v-select>
                <v-text-field
                  v-if="capitanes.length == 0"
                  label="Nombre Capitán"
                  placeholder="Nombre Capitán"
                  v-model="traslado.capitan"
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- alert bitacora de traslado -->
            <v-row v-if="alert_traslado.mostrar">
                <v-col>
                  <v-alert border="left" outlined text :type="alert_traslado.tipo">
                    <v-row>
                      <v-col>
                        <h4>{{ alert_traslado.mensaje }}</h4>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn :color="alert_traslado.color_boton" elevation="2" small dark
                          @click="cerrar_alert('traslado')">Cerrar</v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="alert_traslado.errores.length > 0">
                      <v-col>
                        <ul>
                          <li v-for="e in alert_traslado.errores" :key="e">
                            {{ e }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
              <!-- alert bitacora de traslado-->
            <v-row>
              <v-col cols="6">
                <v-btn
                  color=""
                  @click="guardarTraslado(1)"
                  >GUARDAR TEMPORAL</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn color="green" @click="guardarTraslado(0)"
                  >GUARDAR TRASLADO</v-btn
                >
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--BITACORA DE TRASLADO-->

        <!--BITACORA DE DESCARGA-->
        <v-expansion-panel v-if="mostrar.descarga">
          <v-expansion-panel-header color="#263238" style="color: #fff;">
            
            <div>
              BITACORA DE DESCARGA A VIVERO/ACOPIO
                <v-icon color="green" title="Guadado Definitivo" v-if="descarga.temporal == 0">mdi-check-circle</v-icon>
                <v-icon color="orange" title="Guadado Temporal" v-if="descarga.temporal == 1">mdi-clock</v-icon>
              </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mx-0 mt-1">
              <v-col cols="2">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Folio Parte"
                  label="Folio Parte"
                  v-model="parte.folio"
                  dense
                  :readonly="true"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Guia de despacho"
                  label="Guia de despacho"
                  v-model="descarga.guia_despacho"
                  dense
                  :readonly="true"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Fecha de despacho"
                  label="Fecha de despacho"
                  :value="descarga.fecha_despacho | format_fecha"
                  dense
                  :readonly="true"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Cliente"
                  label="Cliente"
                  v-model="descarga.cliente"
                  dense
                  :readonly="true"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Acopio"
                  label="Acopio"
                  v-model="descarga.acopio"
                  dense
                  :readonly="true"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Hora Recalada"
                  label="Hora Recalada"
                  :value="descarga.recalada | format_date_spanish"
                  dense
                  :readonly="true"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Hora Atraco"
                  label="Hora Atraco"
                  :value="descarga.atraco | format_date_spanish"
                  dense
                  :readonly="true"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Hora Inicio"
                  label="Hora Inicio"
                  :value="descarga.hora_inicio | format_date_spanish"
                  dense
                  :readonly="true"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Hora Termino"
                  label="Hora Termino"
                  :value="descarga.hora_termino | format_date_spanish"
                  dense
                  :readonly="true"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  prepend-icon="mdi-barcode"
                  placeholder="Especie"
                  label="Especie"
                  v-model="descarga.especie"
                  dense
                  :readonly="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  dense
                  prepend-icon="mdi-seal"
                  label="Muestreo Repla"
                  placeholder="Muestreo Repla"
                  counter="30"
                  v-model="descarga.muestreo_repla"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  prepend-icon="mdi-seal"
                  label="Entidad Muestradora"
                  placeholder="Entidad Muestradora"
                  counter="30"
                  v-model="descarga.entidad_muestradora"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  prepend-icon="mdi-seal"
                  placeholder="Mortalidad"
                  label="Mortalidad"
                  counter="30"
                  v-model="descarga.mortalidad"
                ></v-text-field>
              </v-col>
            </v-row>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">
                Parámetros medioambientales previo a descarga
              </legend>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    dense
                    prepend-icon="mdi-seal"
                    label="Saturacion"
                    placeholder="Saturacion"
                    suffix="%"
                    type="number"
                    v-model="descarga.saturacion_centro"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    dense
                    prepend-icon="mdi-temperature-celsius"
                    label="Temperatura"
                    placeholder="Temperatura"
                    type="number"
                    suffix="° C"
                    v-model="descarga.temperatura_centro"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    dense
                    prepend-icon="mdi-seal"
                    label="Oxigeno"
                    placeholder="Oxigeno"
                    suffix="mg/L"
                    type="number"
                    v-model="descarga.oxigeno_centro"
                  ></v-text-field>
                </v-col>
              </v-row>
            </fieldset>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">Descargas a Jaulas</legend>
              <v-btn @click="addDescarga()" color="green"
                ><v-icon>exposure_plus_1</v-icon></v-btn
              >
              <v-simple-table dense class="mx-2 my-2 elevation-3 text-center">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center blue-grey darken-4 white-text">
                        N°
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Bodega
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Hora Inicio
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Hora Término
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        N° Jaula Descarga
                      </th>
                      <th class="text-center blue-grey darken-4 white-text">
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(descarga, idx3) in descarga.descargas_jaulas"
                      :key="descarga.numero"
                    >
                      <td>{{ descarga.numero }}</td>
                      <td>
                        <v-select
                          v-model="descarga.bodegas_id"
                          :items="bodegas"
                          item-text="nombre"
                          item-value="id"
                          label="Destino"
                        >
                        </v-select>
                      </td>
                      <td>
                        <v-datetime-picker
                          label="Inicio (Fecha Hora)"
                          v-model="descarga.hora_inicio"
                          dateFormat="dd-MM-yyyy"
                          :datePickerProps="{
                            locale: 'es-es',
                            prevIcon: 'chevron_left',
                            nextIcon: 'chevron_right',
                          }"
                          :timePickerProps="{ format: '24hr' }"
                        >
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <td>
                        <v-datetime-picker
                          label="Termino (Fecha Hora)"
                          v-model="descarga.hora_termino"
                          dateFormat="dd-MM-yyyy"
                          :datePickerProps="{
                            locale: 'es-es',
                            prevIcon: 'chevron_left',
                            nextIcon: 'chevron_right',
                          }"
                          :timePickerProps="{ format: '24hr' }"
                        >
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <td>
                        <v-text-field v-model="descarga.jaula"></v-text-field>
                      </td>
                      <td>
                        <a @click="deleteDescargaJaula(idx3)"
                          ><span class="material-icons">delete</span></a
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </fieldset>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  solo
                  counter="500"
                  label="Observaciones"
                  v-model="descarga.observaciones"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-if="capitanes.length > 0"
                  v-model="descarga.capitan"
                  :items="capitanes"
                  item-text="nombre"
                  item-value="id"
                  label="Nombre Capitán"
                  dense
                >
                </v-select>
                <v-text-field
                  v-if="capitanes.length == 0"
                  label="Nombre Capitán"
                  placeholder="Nombre Capitán"
                  v-model="descarga.capitan"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-if="tecnicos.length > 0"
                  v-model="descarga.tecnico"
                  :items="tecnicos"
                  item-text="nombre"
                  item-value="id"
                  label="Técnico Wellboat"
                  dense
                >
                </v-select>
                <v-text-field
                  v-if="tecnicos.length == 0"
                  label="Técnico Wellboat"
                  placeholder="Técnico Wellboat"
                  v-model="descarga.tecnico"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  prepend-icon="mdi-seal"
                  placeholder="Encargado Acopio"
                  label="Encargado Acopio"
                  v-model="descarga.encargado_acopio"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- alert bitacora de descarga -->
            <v-row v-if="alert_descarga.mostrar">
                <v-col>
                  <v-alert border="left" outlined text :type="alert_descarga.tipo">
                    <v-row>
                      <v-col>
                        <h4>{{ alert_descarga.mensaje }}</h4>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn :color="alert_descarga.color_boton" elevation="2" small dark
                          @click="cerrar_alert('descarga')">Cerrar</v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="alert_descarga.errores.length > 0">
                      <v-col>
                        <ul>
                          <li v-for="e in alert_descarga.errores" :key="e">
                            {{ e }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                    <v-row v-if="alert_descarga.warnnings.length > 0">
                      <v-col>
                        <ul >
                          <li v-for="e in alert_descarga.warnnings" :key="e" style="background-color: #8ecae6; color:#3c3c3c; border-radius: 5px; padding:1em; list-style-type:none;">
                            - {{ e }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
              <!-- alert bitacora de descarga-->

            <v-row>
              <v-col cols="6">
                <v-btn
                  color=""
                  @click="guardarDescarga(1)"
                  :disabled="descarga.temporal == 0"
                  >GUARDAR TEMPORAL</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn color="green" @click="guardarDescarga(0)"
                  >GUARDAR DESCARGA</v-btn
                >
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--BITACORA DE DESCARGA-->

        <!-- BITACORA DE DESCARGA A PLANTA -->
        <v-expansion-panel v-show="mostrar.descarga_planta">
            <v-expansion-panel-header color="#263238" style="color: #fff;">
              <div>
                BITACORA DE DESCARGA DIRECTA A PLANTA
                <v-icon color="green" title="Guadado Definitivo"
                  v-if="descarga_planta.temporal == 0">mdi-check-circle</v-icon>
                <v-icon color="orange" title="Guadado Temporal" v-if="descarga_planta.temporal == 1">mdi-clock</v-icon>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mx-0 mt-1">
                <v-col cols="2">
                  <v-chip>{{ `FOLIO: ${parte.folio}` }}</v-chip>
                </v-col>
                <!-- <v-col cols="2">
                  <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Guia de Despacho"
                    label="Guia de Despacho"
                    v-model="descarga_planta.guia"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaGuiaPlanta"
                        label="Fecha guía de despacho"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="descarga_planta.fecha"
                      @input="menu2 = false"
                      locale="es-cl"
                      prev-icon="chevron_left"
                      next-icon="chevron_right"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    placeholder="Cliente"
                    label="Cliente"
                    v-model="descarga_planta.empresa"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    placeholder="Muestreo"
                    label="Muestreo"
                    v-model="descarga_planta.muestreo"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Especie"
                    label="Especie"
                    v-model="descarga_planta.especie"
                    dense
                  ></v-text-field>
                </v-col> -->
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-text-field placeholder="Muestreo" label="Muestreo" v-model="descarga_planta.muestreo" dense>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-simple-table>
                    <thead class="bg-deep-blue">
                      <tr>
                        <th class="text-white">Cliente</th>
                        <th class="text-white">Guía Despacho</th>
                        <th class="text-white">Fecha Despacho</th>
                        <th class="text-white">Especie</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="carga in descarga_planta.cargas" :key="carga.id">
                        <td>{{ carga.nombre_empresa }}</td>
                        <td>{{ carga.guia_despacho }}</td>
                        <td>{{ carga.fecha_despacho | format_fecha }}</td>
                        <td>{{ carga.nombre_especie }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <small>NOTA: Los datos de cliente, guia de despacho, fecha de
                    despacho y especie son automáticos y se obtienen de la
                    información del parte y bitácoras de carga</small>
                </v-col>
              </v-row>
              <!-- <pre v-if="descarga_planta.cargas">{{
                descarga_planta.cargas[0].descargas_horas
              }}</pre> -->
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">
                  Detalle de Tiempo de Descarga de la Planta
                </legend>
                <v-tabs center-active v-model="tab">
                  <v-tab v-for="carga in descarga_planta.cargas" :key="carga.id">{{ `Carga Guía: ${carga.guia_despacho}`
                  }}</v-tab>
                  <!-- <v-tab v-if="parte1.id > 0">Carga Guia: {{ parte1.carga.guia_despacho }}</v-tab>
                <v-tab v-if="parte2.id > 0">Carga Guia: {{ parte2.carga.guia_despacho }}</v-tab> -->
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item v-for="carga in descarga_planta.cargas" :key="carga.id">
                    <v-btn @click="agregarDescargaHora(carga)" class="my-1"
                      color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
                    <v-simple-table dense class="mx-0 my-1 elevation-3 text-center">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th rowspan="1" class="text-center blue-grey darken-4 white-text">
                              Número
                            </th>
                            <th rowspan="1" class="text-center blue-grey darken-4 white-text">
                              Hora Inicio
                            </th>
                            <th rowspan="1" class="text-center blue-grey darken-4 white-text">
                              Hora termino
                            </th>
                            <th colspan="1" class="text-center blue-grey darken-4 white-text">
                              Motivo
                            </th>
                            <th colspan="1" class="text-center blue-grey darken-4 white-text">
                              Eliminar
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(hora, idx) in carga.descargas_horas" :key="idx">
                            <td>{{ hora.numero }}</td>
                            <td style="border-right: 1px solid black;width: 180px;">
                              <v-datetime-picker label="Hora Inicio" v-model="hora.hora_inicio" :key="hora.key"
                                dateFormat="dd-MM-yyyy" :datePickerProps="{
                                  locale: 'es-es',
                                  prevIcon: 'chevron_left',
                                  nextIcon: 'chevron_right',
                                }" :timePickerProps="{ format: '24hr' }">
                                <template slot="dateIcon">
                                  <v-icon>calendar_today</v-icon>
                                </template>
                                <template slot="timeIcon">
                                  <v-icon>access_time</v-icon>
                                </template>
                              </v-datetime-picker>
                            </td>
                            <td style="border-right: 1px solid black;width: 180px;">
                              <v-datetime-picker label="Hora Término" v-model="hora.hora_termino" :key="hora.key"
                                dateFormat="dd-MM-yyyy" :datePickerProps="{
                                  locale: 'es-es',
                                  prevIcon: 'chevron_left',
                                  nextIcon: 'chevron_right',
                                }" :timePickerProps="{ format: '24hr' }">
                                <template slot="dateIcon">
                                  <v-icon>calendar_today</v-icon>
                                </template>
                                <template slot="timeIcon">
                                  <v-icon>access_time</v-icon>
                                </template>
                              </v-datetime-picker>
                            </td>
                            <td>
                              <v-text-field v-model="hora.motivo"></v-text-field>
                            </td>
                            <td>
                              <button @click="quitarDescargaHora(hora, carga)">
                                <span class="material-icons">delete</span>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>
                </v-tabs-items>
              </fieldset>
              <v-row class="mt-6">
                <v-col cols="4">
                  <v-select v-if="tecnicos.length > 0" v-model="descarga_planta.tecnico_wellboat" :items="tecnicos"
                    item-text="nombre" item-value="id" label="Técnico Wellboat" dense>
                  </v-select>
                  <v-text-field v-if="tecnicos.length == 0" label="Técnico Wellboat" placeholder="Técnico Wellboat"
                    v-model="descarga_planta.tecnico_wellboat" dense>
                  </v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-select v-if="capitanes.length > 0" v-model="descarga_planta.capitan" :items="capitanes"
                    item-text="nombre" item-value="id" label="Nombre Capitán" dense>
                  </v-select>
                  <v-text-field v-if="capitanes.length == 0" label="Nombre Capitán" placeholder="Nombre Capitán"
                    v-model="descarga_planta.capitan" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Encargado" placeholder="Encargado" v-model="descarga_planta.encargado" dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea solo counter="500" label="Observaciones"
                    v-model="descarga_planta.observaciones"></v-textarea>
                </v-col>
              </v-row>

              <!-- alert bitacora de descarga planta -->
              <v-row v-if="alert_descarga_planta.mostrar">
                <v-col>
                  <v-alert border="left" outlined text :type="alert_descarga_planta.tipo">
                    <v-row>
                      <v-col>
                        <h4>{{ alert_descarga_planta.mensaje }}</h4>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn :color="alert_descarga_planta.color_boton" elevation="2" small dark
                          @click="cerrar_alert('descarga_planta')">Cerrar</v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="alert_descarga_planta.errores.length > 0">
                      <v-col>
                        <ul>
                          <li v-for="e in alert_descarga_planta.errores" :key="e">
                            {{ e }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
              <!-- alert bitacora de descarga planta-->

              <v-row>
                <v-col cols="6">
                  <v-btn color="" @click="guardarDescargaPlanta(1)" :disabled="descarga_planta.temporal == 0">GUARDAR
                    TEMPORAL</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn color="green" @click="guardarDescargaPlanta(0)">GUARDAR DESCARGA</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- BITACORA DE DESCARGA A PLANTA -->
      </v-expansion-panels>

      <!-- alert finalizar servicio -->
    <v-row v-if="alert_finalizar.mostrar">
      <v-col>
        <v-alert border="left" outlined text :type="alert_finalizar.tipo">
          <v-row>
            <v-col>
              <h4>{{ alert_finalizar.mensaje }}</h4>
            </v-col>
            <v-col class="text-right">
              <v-btn :color="alert_finalizar.color_boton" elevation="2" small dark
                @click="cerrar_alert('finalizar')">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="alert_finalizar.errores.length > 0">
            <v-col>
              <ul>
                <li v-for="e in alert_finalizar.errores" :key="e">
                  {{ e }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <!-- alert finalizar servicio-->

      <v-row v-if="mostrar.finalizar_servicio">
      <v-col cols="6">
        <v-file-input v-model="archivos" label="Adjuntar documentación" show-size counter multiple
          prepend-icon="attach_file" chips accept=".pdf"></v-file-input>
      </v-col>
      <v-col cols="12">
        <v-btn color="green" @click="finalizarServicioWellboat" :disabled="archivos.length == 0">FINALIZAR SERVICIO
          WELLBOAT</v-btn>
      </v-col>
    </v-row>
    </v-col>
  </div>
</template>
<script>
import TicketBitacoraNew from "@/components/TicketBitacoraNew.vue";
import { mapState } from "vuex";

export default {
  data: () => ({
    parameters: [],
    especies: [],
    capitanes: [],
    tecnicos: [],
    bodegas: [],
    partes_temporales: [],
    archivos:[],
    parte: {},
    carga: {},
    traslado: {},
    descarga:{},
    descarga_planta:{},
    mostrar: {
      carga: false,
      traslado: false,
      descarga: false,
      descarga_planta: false,
    },
    nbodega1: "N° sello babor",
    nbodega2: "N° sello central",
    nbodega3: "N° sello estribor",
    panel: 0,
    termino_cam: null,
    menu1: false,
    alert_carga: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    alert_traslado: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    alert_descarga: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
      warnnings:[]
    },
    alert_descarga_planta: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    alert_finalizar: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    ids_bitacoras:[],
    tab: 0,
    url_pdf_descarga_planta:null,
    traslados_parametros_ids_eliminar:[],
    cargas_bodegas_ids_eliminar : [],
    cargas_parametros_ids_eliminar : [],
    descargas_jaulas_ids_eliminar:[],
    descargas_horas_ids_eliminar:[]
  }),
  async mounted() {
    this.especies = this.$store.getters["especie/getActive"];
    await this.cargarPartesTemporales();
    await this.loadParameters();
  },
  methods: {
    async cargarPartesTemporales() {
      let url = `${this.base_url}partes/temporales/wellboat/${this.user.wellboat.id}/tipo_parte/1`;

      /*let url = `${this.base_url}partes/portipo/temporal`;
      if (this.user.wellboat != undefined && this.user.wellboat.id > 0) {
        url = `${this.base_url}partes/temporales/wellboat/cosecha/${this.user.wellboat.id}`;
      }*/
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.partes_temporales = response.data.data;
          if (this.partes_temporales.length > 0) {
            this.seleccionParte(this.partes_temporales[0]);
          }

          /*this.temporales = response.data;
        if (this.temporales.length > 0 && this.user.wellboat != undefined && this.user.wellboat.id > 0) {
          let parte = this.temporales[0];
          if (parte) {
            this.parte = parte;
            this.loadTecnicos();
            this.parte = parte;
            this.loadCargaParte();
            if (this.parte.wellboats_id == 7) {
              // don mauro
              this.nbodega1 = 'N° sello Proa';
              this.nbodega2 = 'N° sello Popa';
            }
          }
        }*/
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async seleccionParte(parte) {
      // console.log("parte", parte.id);
      this.parte = parte;
      if (this.parte.wellboats_id == 7) {
        // don mauro
        this.nbodega1 = "N° sello Proa";
        this.nbodega2 = "N° sello Popa";
      }

      //actualizar parte seleccionado (para mostrar en color visualmente el parte seleccionado)
      let idx = this.partes_temporales.findIndex((t) => t.id == this.parte.id);
      this.partes_temporales.forEach((p, i) => {
        p.seleccionado = i == idx ? true : false;
      });

      //carga de bodegas del wellboat
      await this.cargarBodegas();

      //cargando bitacoras del parte
      await this.cargarBitacoras();

      await this.cargarTecnicos();
      await this.cargarCapitanes();

      // console.log(`es descarga: ${this.parte.es_descarga}`);
    },
    async cargarBitacoras() {
      this.carga = {
        lances: [],
        bodegas: [],
      };
      this.traslado = {
        parametros:[]
      };
      this.descarga = {
        descargas_jaulas:[]
      };
      this.descarga_planta = {};

      let url = `${this.base_url}partes/${this.parte.id}/bitacoras`;
      await this.axios.get(url, this.headers_json).then((r) => {
        console.log("data", r.data);
        //bitacora de carga
        this.carga = r.data.carga;
        if (this.parte.termino_cam) {
          this.termino_cam = this.moment(this.parte.termino_cam).toDate();
        }
        this.formatearFechaParaDatepicker("carga");
        this.setearParametros();
        //bitacora de carga

        //bitacora de traslado
        if (r.data.traslado != null) {
          this.traslado = r.data.traslado;
          this.formatearParametrosTraslado();
        }
        //bitacora de traslado

        //bitacora de descarga
        if (r.data.descarga != null) {
          this.descarga = r.data.descarga;
          this.formatearFechaParaDatepicker("descarga");
        }
        //bitacora de descarga

        //bitacora de descarga directa a planta
        if (r.data.descarga_planta != null) {
          this.descarga_planta = r.data.descarga_planta;
          this.formatearFechaParaDatepicker("descarga_planta");
        }
        //bitacora de descarga directa a planta

        this.mostrar = r.data.mostrar;

        this.ids_bitacoras = r.data.ids_bitacoras;
      });
    },
    setearParametros() {
      this.parameters.forEach((p, idx) => {
        let par = this.carga.parametros.find(
          (item) => item.parametros_id == p.id
        );
        if (par) {
          this.$set(this.parameters[idx], "valor", par.parametros_opciones_id);
        }
      });
    },
    async loadParameters() {
      let url = `${this.base_url}tipos_bitacoras/${1}/cargar_parametros`;
      await this.axios.get(url, this.headers_json).then((r) => {
        this.parameters = r.data;
      });
    },
    async cargarTecnicos() {
      let url = `${this.base_url}cargas/tecnicos/${this.user.wellboat.id}/${this.parte.folio}`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.tecnicos = response.data;
          // this.carga.tecnico_wellboat = this.tecnicos[0];
          // this.traslado.tecnico_wellboat = this.tecnicos[0];
          // this.descarga.tecnico_wellboat = this.tecnicos[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async cargarCapitanes() {
      let url = `${this.base_url}cargas/capitanes/${this.user.wellboat.id}/${this.parte.folio}`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.capitanes = response.data;
          //this.carga.tecnico_wellboat = this.capitanes[0];
          //this.traslado.tecnico_wellboat = this.capitanes[0];
          //this.descarga.tecnico_wellboat = this.capitanes[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async cargarBodegas() {
      let fecha_viaje = this.parte.recalada? this.parte.recalada : this.parte.zarpe;
      let url = `${this.base_url}wellboats/${this.parte.wellboats_id}/bodegas/${fecha_viaje}`;
      //let url = `${this.base_url}wellboats/${this.parte.wellboats_id}/bodegas`;
      await this.axios.get(url, this.headers_json).then((r) => {
        this.bodegas = r.data.data;
      });
    },
    formatearFechaParaDatepicker(bitacora = "") {
      if (bitacora == "carga") {
        //reformateando fechas carga (para mostrar en datepicker)
        this.carga.lances.forEach((l) => {
          l.key = this.generarKey(5);
          l.hora_inicio = this.$options.filters.format_string_a_fecha(
            l.hora_inicio
          );
          l.hora_termino = this.$options.filters.format_string_a_fecha(
            l.hora_termino
          );

          l.hora_medicion = this.$options.filters.format_string_a_fecha(
            l.hora_medicion
          );
          this.calcularCargaEfectiva(l)
        });

        let n = 1;
        this.carga.bodegas.forEach((b) => {
          b.numero = n++;
        });
      }

      if(bitacora == "traslado"){
        this.traslado.parametros.forEach(p => {
          p.key = this.generarKey(5);
          p.hora = this.$options.filters.format_string_a_fecha(p.hora)
        })
      }

      if(bitacora == "descarga"){
        this.descarga.descargas_jaulas.forEach((dj,idx) => {
          dj.key = this.generarKey(5)
          dj.numero = idx + 1
          dj.hora_inicio = this.$options.filters.format_string_a_fecha(dj.hora_inicio)
          dj.hora_termino = this.$options.filters.format_string_a_fecha(dj.hora_termino)
        })
      }

      if (bitacora == "descarga_planta") {
        //reformateando fechas carga (para mostrar en datepicker)
        this.descarga_planta.cargas.forEach((c) => {
          c.descargas_horas.forEach((h) => {
            h.key = this.generarKey(5);
            h.hora_inicio = this.$options.filters.format_string_a_fecha(
              h.hora_inicio
            );
            h.hora_termino = this.$options.filters.format_string_a_fecha(
              h.hora_termino
            );
          });
        });
      }
    },
    calcularCargaEfectiva(lance) {
      if (lance.hora_inicio != null && lance.hora_termino != null) {
        let h2 = this.moment(lance.hora_termino);
        let h1 = this.moment(lance.hora_inicio);
        let seconds = h2.diff(h1, "seconds");
        let millis = this.moment
          .duration({ seconds: seconds })
          .asMilliseconds();
        let tiempo = this.moment.utc(millis).format("HH:mm");
        const index = this.carga.lances.indexOf(lance);
        lance.carga_efectiva = tiempo;
        this.$set(this.carga.lances, index, lance);
      }
    },
    eliminarLance(index) {
      //const index = this.carga.lances.indexOf(item);
      if(this.carga.lances[index].id){
        this.cargas_parametros_ids_eliminar.push(this.carga.lances[index].id);
      }
      this.carga.lances.splice(index, 1);
      //enumerar lances
      let num = 1;
      this.carga.lances.forEach((b, i) => {
        this.$set(this.carga.lances[i], "numero", num);
        num++;
      });
    },
    async eliminarBodega(index) {
      //const index = this.carga.lances.indexOf(item);
      if(this.carga.bodegas[index].id){
      this.cargas_bodegas_ids_eliminar.push(this.carga.bodegas[index].id);
        
      }
      this.carga.bodegas.splice(index, 1);
      //enumerar lances
      let num = 1;
      this.carga.bodegas.forEach((b, i) => {
        this.$set(this.carga.bodegas[i], "numero", num);
        num++;
      });
    },
    agregarLance() {
      console.log("llega", this.carga.lances);
      let n = this.carga.lances.length;
      let lance = {
        id: null,
        key: this.generarKey(5),
        menu1: false,
        menu2: false,
        menu3: false,
        numero: n + 1,
        hora_inicio: null,
        hora_medicion: null,
        oxigeno: null,
        saturacion: null,
        temperatura: null,
        ph: null,
        peces: null,
        hora_termino: null,
        carga_efectiva: null,
      };
      //this.carga.lances.push(lance)
      this.$set(this.carga.lances, n, lance);
    },
    agregarBodega() {
      let n = this.carga.bodegas.length;
      let b = {
        id: null,
        numero: n + 1,
        bodegas_id: null,
        peces: null,
        peso: null,
        biomasa: null,
        densidad: null,
        lances: null,
        jaula: null,
        estanques: null,
        hora_inicio: null,
        hora_termino: null,
      };
      this.carga.bodegas.push(b);
    },
    async sincronizar() {
      /*let parte = this.temporales[0];
      if (parte != undefined) {
        let inicio = parte.zarpe.substring(0, 10);
        let termino = parte.recalada.substring(0, 10);
        //console.log(inicio, termino)
        let url = `${this.base_url}turnos/obtener`;
        await this.axios.post(url, { fecha: inicio }, this.headers_json).then((response) => {
          console.log(response);
          if (inicio != termino) {
            this.axios.post(url, { fecha: termino }, this.headers_json).then((response) => {
              console.log(response);
              this.onClickTicket(parte);
            });
          } else {
            this.onClickTicket(parte);
          }
        })
      }*/
    },
    generarKey(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    async guardarCarga(temporal = 1) {
      this.cerrar_alert("carga");
      //this.carga.temporal = temporal;
      this.carga.parametros = this.parameters;

      //reformateando fechas
      this.carga.lances.forEach((l) => {
        l.hora_inicio = this.$options.filters.format_fecha_hora(l.hora_inicio);
        l.hora_termino = this.$options.filters.format_fecha_hora(
          l.hora_termino
        );
        l.hora_medicion = this.$options.filters.format_fecha_hora(
          l.hora_medicion
        );
      });

      this.carga.bodegas.forEach((b) => {
        b.hora_inicio = this.$options.filters.format_fecha_hora(b.hora_inicio);
        b.hora_termino = this.$options.filters.format_fecha_hora(
          b.hora_termino
        );
      });

      let fecha_cam = this.termino_cam
        ? this.moment(this.termino_cam).format("YYYY-MM-DD HH:mm")
        : null;

      this.$set(this.carga, "termino_cam", fecha_cam);
      if (this.carga.es_nueva) {
        this.carga.natacion = this.buscar_valor_parametro(1);
        this.carga.boqueo = this.buscar_valor_parametro(2);
        this.carga.descamacion = this.buscar_valor_parametro(3);
        this.carga.esquinados = this.buscar_valor_parametro(4);
        this.carga.lobos = this.buscar_valor_parametro(5);
        this.carga.heridas = this.buscar_valor_parametro(6);
        this.carga.mortalidad_cerco = this.buscar_valor_parametro(7);
      }
      //console.log("carga:", this.carga);
      this.cargas_bodegas_ids_eliminar.forEach(async (id)=>{
        await this.axios.delete(`${this.base_url}cargas_bodegas/${id}`, this.headers_json)
      });

      this.cargas_parametros_ids_eliminar.forEach(async (id) => {
        await this.axios.delete(`${this.base_url}cargas_parametros/${id}`,this.headers_json)
      });

      this.cargas_bodegas_ids_eliminar = [];
      this.cargas_parametros_ids_eliminar = [];

      let cargaData = Object.assign({}, this.carga);
      cargaData.temporal = temporal;

      //let url = this.carga.id != null? `${this.base_url}cargas/${this.carga.id}`:`${this.base_url}cargas`
      let url = `${this.base_url}cargas/guardar`;
      await this.axios
        .post(url, cargaData, this.headers_json)
        .then((r) => {
          this.alert_carga.mostrar = true;
          this.alert_carga.tipo = "success";
          this.alert_carga.color_boton = "green";
          this.alert_carga.mensaje = r.data.message;
          //this.carga = r.data.data;
          //this.formatearFechaParaDatepicker("carga");
          this.cargarBitacoras();

          this.url_pdf_carga = r.data.url_pdf;
          if (this.url_pdf_carga != null) {
            window.open(`${this.storage_url}${this.url_pdf_carga}`);
          }
        })
        .catch((e) => {
          this.alert_carga.mostrar = true;
          this.alert_carga.tipo = "error";
          this.alert_carga.color_boton = "red";
          this.alert_carga.mensaje = e.response.data.message;
          this.alert_carga.errores = e.response.data.errors;
          this.carga.temporal = 1;
        });
    },
    cerrar_alert(tipo = "") {
      let alert = {
        mostrar: false,
        tipo: "",
        color_boton: "",
        mensaje: "",
        errores: [],
      };
      if (tipo == "carga") {
        this.alert_carga = alert;
      }
      if (tipo == "traslado") {
        this.alert_traslado = alert;
      }
      if (tipo == "descarga") {
        alert.warnnings = [];
        this.alert_descarga = alert;
      }
      if (tipo == "descarga_planta") {
        this.alert_descarga_planta = alert;
      }
      if (tipo == "finalizar") {
        this.alert_finalizar = alert;
      }
    },
    /*recalcular(item) {
      let index = this.carga.bodegas.indexOf(item);
      let bodega = item;
      bodega.biomasa = (bodega.peces * bodega.peso).toFixed(2);
      let m3 = this.bodegas.find(b => b.bodega.id == item.bodega.id).m3
      bodega.densidad = (bodega.biomasa / m3).toFixed(2);
      this.$set(this.carga.bodegas, index, bodega);
    },*/

    calcularBiomasa(bodega) {
      let idx = this.carga.bodegas.findIndex((b) => b.numero == bodega.numero);
      let peso = bodega.peso?.toString().replace(",", ".");
      let bod = this.bodegas.find((b) => b.id == bodega.bodegas_id);
      let biomasa = Math.round(bodega.peces * peso);
      let densidad = (biomasa / bod.m3).toFixed(2);
      this.$set(this.carga.bodegas[idx], "biomasa", biomasa);
      this.$set(this.carga.bodegas[idx], "densidad", densidad);
    },

    buscar_valor_parametro(id) {
      let valor = this.parameters.find((p) => p.id == id).valor;
      return valor;
    },

    addMedicionTraslado() {
      let n = this.traslado.parametros.length;
      let parametro = {
        id: null,
        menu1: false,
        traslados_id: null,
        numero: n + 1,
        hora: null,
        bodegas: [],
      };
      this.bodegas.forEach((b) => {
        // parametro['b'+b.bodega.id] = {oxigeno: 0, ph: 0, uv: 0, temperatura: 0}
        parametro.bodegas.push({
          oxigeno: null,
          ph: null,
          uv: null,
          temperatura: null,
          bodegas_id: b.id,
          id: this.generarKey(5),
        });
      });
      this.traslado.parametros.push(parametro);
    },
    checkGuia() {
      if (
        /^[0-9-]+$/.test(this.traslado.guia_despacho) == false ||
        /^[0-9-]+$/.test(this.traslado.guia_despacho_2)
      ) {
        this.validation_message = "Formato no válido en guía de despacho";
        this.showSnackBar(true);
      }
    },
    deleteParametroTraslado(index) {
      //const index = this.traslado.parametros.indexOf(item);
      console.log('index '+index)
      console.log(this.traslado.parametros[index])
      this.traslado.parametros[index].bodegas.forEach(async b => {
        if(!isNaN(b.id)){
          this.traslados_parametros_ids_eliminar.push(b.id);
          //console.log('id '+b.id)
          /*await this.axios.delete(`${this.base_url}traslados_parametros/${b.id}`,this.headers_json).then(res => {
            console.log(res.data)
            console.log('Eliminado id: '+b.id)
          })*/
        }
      });
      this.traslado.parametros.splice(index, 1);
      //enumerar parametros traslado
      let num = 1;
      this.traslado.parametros.forEach((p, i) => {
        this.$set(this.traslado.parametros[i], "numero", num);
        num++;
      });
    },

    deleteDescargaJaula(index) {

      let id = this.descarga.descargas_jaulas[index].id;
      if(id){
        this.descargas_jaulas_ids_eliminar.push(id);
      }
      this.descarga.descargas_jaulas.splice(index, 1);
      //enumerar descargas jaulas
      let num = 1;
      this.descarga.descargas_jaulas.forEach((p, i) => {
        this.$set(this.descarga.descargas_jaulas[i], "numero", num);
        num++;
      });
    },

    async guardarTraslado(temporal = 1) {
      this.cerrar_alert("traslado");
      //this.traslado.temporal = temporal;
      let trasladoData = Object.assign({}, this.traslado);
      trasladoData.temporal = temporal;
      let url = `${this.base_url}traslados/guardar`;

      trasladoData.parametros.forEach((p) => {
        p.hora = this.$options.filters.format_fecha_hora(p.hora);
      });

      this.traslados_parametros_ids_eliminar.forEach(async (id) => {
        await this.axios.delete(`${this.base_url}traslados_parametros/${id}`,this.headers_json)
      });

      this.traslados_parametros_ids_eliminar = [];

      await this.axios
        .post(url, trasladoData, this.headers_json)
        .then((r) => {
          this.alert_traslado.mostrar = true;
          this.alert_traslado.tipo = "success";
          this.alert_traslado.color_boton = "green";
          this.alert_traslado.mensaje = r.data.message;
          this.seleccionParte(this.parte)
          //this.traslado = r.data.data;
          //this.formatearParametrosTraslado();
          this.url_pdf_traslado = r.data.url_pdf;
          if (this.url_pdf_traslado != null) {
            window.open(`${this.storage_url}${this.url_pdf_traslado}`);
          }
        })
        .catch((e) => {
          this.alert_traslado.mostrar = true;
          this.alert_traslado.tipo = "error";
          this.alert_traslado.color_boton = "red";
          this.alert_traslado.mensaje = e.response.data.message;
          this.alert_traslado.errores = e.response.data.errors;
          //this.traslado.temporal = 1;
        });

      // console.log(`traslado temporal: ${temporal}`);
    },
    formatearParametrosTraslado(key = "hora") {
      let array = [...this.traslado.parametros];
      const unikeys = [
        ...new Map(array.map((item) => [item[key], item])).values(),
      ];
      let params = [];
      

      unikeys.forEach((hora) => {
        let bdgas = [];
        this.bodegas.forEach((b) => {
          let find = this.traslado.parametros.find(
            (p) => p.hora == hora.hora && p.bodegas_id == b.id
          );

          if (find) {
            bdgas.push(find);
          } else {
            bdgas.push({
              oxigeno: null,
              ph: null,
              uv: null,
              temperatura: null,
              bodegas_id: b.id,
              id: this.generarKey(5),
            });
          }
        });
        params.push({
          id: null,
          menu1: false,
          traslados_id: this.traslado.id,
          numero: hora.numero,
          hora: this.$options.filters.format_string_a_fecha(hora.hora),
          bodegas: bdgas,
        });
      });

      this.$set(this.traslado,'parametros',params)
    },
    /*addDescarga() {
      let n = this.descarga.jaulas.length;
      let descarga = {
        id: 0,
        menu1: false,
        menu2: false,
        descargas_id: 0,
        bodega: this.bodegas[0],
        numero: n + 1,
        hora_inicio: "00:00",
        hora_termino: "00:00",
        jaula: "",
      };
      this.$set(this.descarga.jaulas, n, descarga);
    },*/

    addDescarga() {
      let n = this.descarga.descargas_jaulas.length;
      let descarga = {
        id: null,
        menu1: false,
        menu2: false,
        descargas_id: null,
        //bodega: null,
        bodegas_id:null,
        numero: n + 1,
        hora_inicio: null,
        hora_termino: null,
        jaula: null,
        smolts: null,
        key: this.generarKey(5),
      };
      this.descarga.descargas_jaulas.push(descarga);
      // this.$set(this.descarga.jaulas, n, descarga);
    },

    quitarDescargaHora(hora, carga) {
      console.log(hora)
      let idx_carga = this.descarga_planta.cargas.findIndex((c) => c.id == carga.id);
      let idx_descarga_hora = this.descarga_planta.cargas[idx_carga].descargas_horas.findIndex(d => d == hora);
      let id = this.descarga_planta.cargas[idx_carga].descargas_horas[idx_descarga_hora].id;
      if(id){
        this.descargas_horas_ids_eliminar.push(id);
      }
      let numero = 1;
      let horas = [...this.descarga_planta.cargas[idx_carga].descargas_horas]
        .filter((e) => e != hora)
        .map((h) => {
          h.numero = numero;
          numero++;
          return h;
        });

      this.descarga_planta.cargas[idx_carga].descargas_horas = horas;
    },

    async guardarDescarga(temporal = 1) {
      this.cerrar_alert("descarga");
      //this.descarga.temporal = temporal;
      let descargaData = Object.assign({}, this.descarga);
      descargaData.temporal = temporal;

      let url = `${this.base_url}descargas/guardar`;

      //reformateando fechas
      descargaData.descargas_jaulas.forEach((j) => {
        j.hora_inicio = this.$options.filters.format_fecha_hora(j.hora_inicio);
        j.hora_termino = this.$options.filters.format_fecha_hora(
          j.hora_termino
        );
      });

      this.descargas_jaulas_ids_eliminar.forEach(async id=>{
        await this.axios.delete(`${this.base_url}descargas_jaulas/${id}`,this.headers_json)
      })
      this.descargas_jaulas_ids_eliminar = []



      await this.axios
        .post(url, descargaData, this.headers_json)
        .then((r) => {
          this.alert_descarga.mostrar = true;
          this.alert_descarga.tipo = "success";
          this.alert_descarga.color_boton = "green";
          this.alert_descarga.mensaje = r.data.message;
          this.alert_descarga.warnnings = r.data.warnnings;
          console.log("r.data:", r.data);
          //this.descarga = r.data.data;
          //this.formatearFechaParaDatepicker("descarga");
          this.cargarBitacoras()
          this.url_pdf_descarga = r.data.url_pdf;
          if (this.url_pdf_descarga != null) {
            window.open(`${this.storage_url}${this.url_pdf_descarga}`);
          }
        })
        .catch((e) => {
          this.alert_descarga.mostrar = true;
          this.alert_descarga.tipo = "error";
          this.alert_descarga.color_boton = "red";
          this.alert_descarga.mensaje = e.response.data.message;
          this.alert_descarga.errores = e.response.data.errors;
          //this.descarga.temporal = 1;
          //this.formatearFechaParaDatepicker("descarga");
        });
    },

    async finalizarServicioWellboat() {
      this.cerrar_alert("finalizar");

      let formData = new FormData();
      // let nf = this.archivos.length;
      //let i = 1;
      this.archivos.forEach((f) => {
        //formData.append("doc"+i, f);
        formData.append("files[]", f);
        //i++;
      });
      formData.append("partes_id", this.parte.id);
      // formData.append('num_files', nf)
      // formData.append('archivos',this.archivos)

      formData.append("cargas_id_1", this.ids_bitacoras?.carga_id_1);
      formData.append("cargas_id_2", this.ids_bitacoras?.carga_id_2);
      formData.append("traslados_id", this.ids_bitacoras?.traslado_id);
      formData.append("descargas_id_1", this.ids_bitacoras?.descarga_id_1);
      formData.append("descargas_id_2", this.ids_bitacoras?.descarga_id_2);
      formData.append("descargas_plantas_id", this.ids_bitacoras?.descarga_planta_id);
      formData.append("tipopartes_id", this.parte.tipopartes_id);
      formData.append("folio", this.parte.folio);
      formData.append("wellboats_id", this.parte.wellboats_id);

      let url = `${this.base_url}bitacoras/finalizar`;

      await this.axios
        .post(url, formData, this.headers_file)
        .then((r) => {
          // console.log("r", r);
          this.alert_finalizar.mostrar = true;
          this.alert_finalizar.tipo = "success";
          this.alert_finalizar.color_boton = "green";
          this.alert_finalizar.mensaje = r.data.message;
        })
        .catch((e) => {
          // console.log("err", e);
          this.alert_finalizar.mostrar = true;
          this.alert_finalizar.tipo = "error";
          this.alert_finalizar.color_boton = "red";
          this.alert_finalizar.mensaje = e.response.data.message;
          this.alert_finalizar.errores = e.response.data.errors;
        });
    },

    agregarDescargaHora(carga) {
      let h = {
        id: null,
        numero: carga.descargas_horas.length + 1,
        key: this.generarKey(5),
        partes_id: carga.partes_id,
        cargas_id: carga.id,
        hora_inicio: null,
        hora_termino: null,
        motivo: null,
      };

      let idx = this.descarga_planta.cargas.findIndex((c) => c.id == carga.id);
      this.descarga_planta.cargas[idx].descargas_horas.push(h);
    },

    async guardarDescargaPlanta(temporal = 1) {
      this.cerrar_alert("descarga_planta");
      this.descarga_planta.temporal = temporal;

      let url = `${this.base_url}descargas_plantas/guardar`;

      //reformateando fechas
      this.descarga_planta.cargas.forEach((c) => {
        c.descargas_horas.forEach((h) => {
          h.hora_inicio_f = this.$options.filters.format_fecha_hora(
            h.hora_inicio
          );
          h.hora_termino_f = this.$options.filters.format_fecha_hora(
            h.hora_termino
          );
        });
      });

      this.descargas_horas_ids_eliminar.forEach(async (id) => {
        await this.axios.delete(`${this.base_url}descargas_horas/${id}`,this.headers_json)
      });
      this.descargas_horas_ids_eliminar = [];

      await this.axios
        .post(url, this.descarga_planta, this.headers_json)
        .then((r) => {
          this.alert_descarga_planta.mostrar = true;
          this.alert_descarga_planta.tipo = "success";
          this.alert_descarga_planta.color_boton = "green";
          this.alert_descarga_planta.mensaje = r.data.message;
          //this.descarga_planta = r.data.data;
          //this.formatearFechaParaDatepicker("descarga_planta");
          this.url_pdf_descarga_planta = r.data.url_pdf;
          if (this.url_pdf_descarga_planta != null) {
            window.open(`${this.storage_url}${this.url_pdf_descarga_planta}`);
          }
          this.seleccionParte(this.parte)
        })
        .catch((e) => {
          this.alert_descarga_planta.mostrar = true;
          this.alert_descarga_planta.tipo = "error";
          this.alert_descarga_planta.color_boton = "red";
          this.alert_descarga_planta.mensaje = e.response.data.message;
          this.alert_descarga_planta.errores = e.response.data.errors;
          this.descarga_planta.temporal = 1;
        });
      // console.log(`descarga planta temporal: ${temporal}`);
      // console.log("descarga_planta", this.descarga_planta);
    },
  },

  computed: {
    ...mapState([
      "storage_url",
      "loading",
      "base_url",
      "headers_json",
      "headers_file",
      "user",
    ]),
    fechaGuiaFormatted() {
      return this.$options.filters.format_fecha(this.carga.fecha_despacho);
    },
    totalPeces() {
      let total = 0;
      this.carga.bodegas.forEach((b) => {
        total += parseFloat(b.peces);
      });
      return total;
    },
    totalPeso() {
      if (this.totalBiomasa != 0 && this.totalPeces != 0) {
        return (this.totalBiomasa / this.totalPeces).toFixed(3);
      }
      return 0;
    },
    totalBiomasa() {
      let total = 0;
      this.carga.bodegas.forEach((b) => {
        total += parseFloat(b.biomasa);
      });
      return total;
    },
    totalDensidad() {
      if (this.carga.bodegas == undefined) return 0;
      let total = 0;
      let m3_bodegas = 0;
      let ids = [];
      this.carga.bodegas.forEach((b) => {
        total += parseFloat(b.biomasa);
        let index = ids.findIndex(id => id == b.bodegas_id);
        if(index == -1){
          let m3 = this.bodegas.find(bod => bod.id == b.bodegas_id)?.m3
          m3_bodegas += m3
          ids.push(b.bodegas_id)
        }
      });
      //let m3_total = this.user.wellboat.m3;
      return (total / m3_bodegas).toFixed(2);
    },
  },
  components: {
    TicketBitacoraNew,
  },
};
</script>
